.MobileBrand {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  height: 500px;
  max-height: 500px;
  margin-bottom: 350px;
}

.mobile_brand_wrapper {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  overflow-y: auto;
  white-space: nowrap; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}