.FieldItem {
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1000px;
  height: 610px;
  padding: 20px;
  overflow: hidden;
}
.FieldItem > img {
  border-radius: 5px;
  cursor: pointer;
}

.MobileFieldItem {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
  overflow: hidden;
}
.MobileFieldItem > img {
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}