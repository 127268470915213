.Footer {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
  background-color: black;
}

.footer_info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer_info > span {
  font-family: NanumR;
  color: white;
  font-size: 14px;
}

.footer_img > img {
  width: 5%;
  min-width: 75px;
  max-width: 75px;
}