.Header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  margin-bottom: 50px;
}

.MobileHeader {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  margin-bottom: 30px;
}

.Header > img {
  width: 5%;
  min-width: 75px;
  max-width: 75px;
  cursor: pointer;
}
.mobile_img{
  width: 2%;
  min-width: 40px;
  max-width: 40px;
  min-height: 30px;
  max-height: 30px;
  
}
.Header > button {
  border: none;
  border-radius: 5px;
  background-color: white;
  height: 40px;
  min-height: 30px;
  padding: 5px 5px;
  white-space: nowrap;
  cursor: pointer;
}
.Header > button:active {
  background-color: rgb(245, 245, 245);
}
.Header > button > img {
  width: 100%;
  height: 100%;
}

.MobileHeader > button > img {
  width: 100%;
  height: 100%;
}