.BrandItem {
  flex: 1 1 calc(20% - 10px);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.BrandItem > img {
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
  display: block;
}

.MobileBrandItem {
  flex: 1 1 calc(33% - 10px);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MobileBrandItem > img {
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
  display: block;
}