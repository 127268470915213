.ProdItem {
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1000px;
  height: 610px;
  padding: 20px;
  overflow: hidden;
}
.ProdItem > img {
  cursor: pointer;
  transition: transform 0.3s ease;
}
.ProdItem > img:hover {
  transform: scale(1.07)
}

.MobileProdItem {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 580px;
  padding: 5px;
  overflow: hidden;
  gap: 20px;
  margin-bottom: 30px;
}
.MobileProdItem > img {
  cursor: pointer;
  width: 50%;
  height: auto;
  transition: transform 0.3s ease;
}

.MobileProdItem > img:hover {
  transform: scale(1.07)
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  gap: 10px;
  white-space: nowrap;
}
.mContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
  gap: 8px;
  white-space: nowrap;
}