.video_container {
  position: relative;
  width: 100%;
  height: auto;
  min-width: 500px;
  max-height: 450px;
  min-height: 280px;
}
.video_container > video {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.video_container > img {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.title_wrapper {
  position: absolute;
  top: 5%;
  left: 5%;
  padding: 10px;
  font-size: clamp(25px, 5vw, 45px);
}

.company {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 40px;
  margin-bottom: 30px;
}

.company > div {
  text-align: center;
  white-space: nowrap;
}