@font-face {
  font-family: Jalnan;
  src: url('/src/assets/fonts/Jalnan2TTF.ttf');
}
body {
  display: flex;
  width: 100%;
  margin: 0px;
  background-color: white;
  /* background-color: aliceblue */
  /* background-color: #34c3af; */
  /* background-color: #05b8a0; */
  /* background-color: rgb(245, 247, 242); */
}

body * {
  font-family: Jalnan;
}

#root {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0px auto;
  padding: 0px 0px;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}


.title {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  padding: 10px;
  margin-bottom: 50px;
}
.mTitle {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 25px;
  padding: 10px;
  margin-bottom: 30px;
}

.first-title {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.onl-second {
  color: orange;
}

.onl-major {
  color: #34c3af;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  height: 810px;
  max-height: 810px;
  margin-bottom: 250px;
}

.mobile_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  padding: 5px;
}

.call_link {
  font-size: 25px;
  border-bottom: 1px solid orange;
  margin-bottom: 100px;
}

.tel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
}

.tel > img {
  width: 100%;
  max-width: 20px;
}

.mobile-slide {
  width: 100%;
  max-width: 420px;
}