.mobile_company {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 25px;
  margin-bottom: 30px;
  gap: 5px;
}

.mobile_company > div {
  text-align: center;
  white-space: nowrap;
}

.mVideo_container {
  position: relative;
  width: 100%;
  height: auto;
}

.mVideo_container > img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.mTitle_wrapper {
  position: absolute;
  top: 5%;
  left: 5%;
  padding: 10px;
  font-size: clamp(25px, 5vw, 45px);
}